<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        class="login"
    >
    <div class="bl_login">
        <login-form />
        <app-button
            name="パスワードを忘れた場合はこちら"
            class="textonly"
            ref="jest-login-button-reset"
            @click="toReset"
        />
    </div>
    <app-button
        name="新規お申し込みはこちら"
        ref="jest-login-button-guide"
        @click="toGuide"
        class="btn_center outlined uq_newaccount"
    />
    </default-template>
</template>

<script>
/**
 * P100 ログイン（兼事業者登録申込）
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 * 
 * [イベント処理]
 * 「ログイン」ボタン押下時 ... 事業者の場合P110 情報提供事業者トップページに、管理者の場合P200 管理者トップページを表示
 *      未承認事業者または該当がない場合はエラーメッセージ表示
 * 「パスワードを忘れた場合はこちら」ボタン押下時 ... P102 パスワードリセットに遷移
 * 「新規お申込みはこちら」ボタン押下時 ... P001 新規事業者案内ページに遷移
 */
import { move } from '@/util'
import LoginForm from '@/components/organisms/LoginForm.vue'
import { mapActions } from 'vuex'

export default {
    name: 'AuthenticationLogin',
    components: {
        LoginForm
    },
    computed:{
        isHouse(){
            return this.$route.path.includes('house')
        },
        typeString(){
            return this.isHouse ? 'house':'building'
        },
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        },
    },
    methods:{
        ...mapActions({
            updateStatus:'status/update',
            update:'auth/update',
            clearError:'error/clear'
        }),
        toReset(){
            this.clearError()
            this.update({
                email:'',
                password:''
            })
            this.updateStatus({type:this.typeString})
            move(this.$router, '/password/email')
        },
        toGuide(){
            this.updateStatus({type:this.typeString})
            move(this.$router, '/organization/guide')
        },
    }
}
</script>

<style scoped>
h2{
    text-align: center;
    margin-top: 7vh;
    margin-bottom: 5vh;
}
.bl_login{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 380px;
    margin: 20px auto;
    padding: 30px;
    border: 1px solid #CDD6DD;
}

.bl_login >>> button.textonly{
    display: inline-block;
    margin: 32px auto 0;
    padding: 0 2px 1px;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
}
button.uq_newaccount{
    width: 340px;
    margin-top: 40px;
}
</style>