<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="isHouse"
    >
        <div class="bl_logout">
            <p>
                ログアウトしました
            </p>
            <app-button
                name="ログインはこちら"
                ref="jest-logout-button-login"
                @click="toLogin"
                class="toLogin"
            />
            <span @click="moveTo('/top')" class="toTop">住宅性能評価・表示協会ホームページへ戻る</span>
        </div>
    </default-template>
</template>

<script>
/**
 * P101 ログアウト
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 * 
 * [イベント処理]
 * 「ログインはこちら」ボタン押下時 ... P100 ログイン（兼事業者登録申込）を表示する。
 *      住宅版からログアウトした場合は住宅版、非住宅版からログアウトした場合は非住宅版ページへ遷移する。
 */
import { move } from '@/util'
import { mapGetters } from 'vuex'

export default {
    name:'AuthenticationLogout',
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse'
        }),
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        }
    },
    methods:{
        toLogin(){
          let path
          path = this.isHouse ? '/login/house' : '/login/building'
          move(this.$router, path)
        },
        moveTo(path){
            move(this.$router, path)
        },
    }
}
</script>
<style>
h2{
    text-align: center;
    margin-top: 7vh;
    margin-bottom: 5vh;
}
.bl_logout{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 380px;
    margin: 20px auto;
    padding: 30px;
    border: 1px solid #CDD6DD;
}

.bl_logout>p{
    margin-bottom: 40px;
}
button.toLogin{
    width: fit-content;
}
span.toTop{
    display: inline-block;
    text-align: center;
    margin-top: 40px;
    padding: 0 2px 1px;
    cursor:pointer;
    border-bottom: 1px solid currentColor;
}
</style>