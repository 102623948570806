<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper uq_passwordEmail">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">パスワードリセット</h2>
            </div>
            <div
                v-if="!isSend"
                key="resetForm"
                ref="jest-password-reset-form"
                class="uq_passwordEmail_isnotsend"
            >
                <div class="ly_form_box">
                    <p>メールアドレスを入力し、「パスワードリセット」ボタンを<br />クリックしてください。</p>
                    <div>
                        <error-list class="notice" />
                        <app-text
                            v-model="email"
                            name="email"
                            label="メールアドレス"
                            class="formrow"
                            ref="jest-password-email"
                        />
                    </div>
                </div>
                <div class="ly_buttonBox">
                    <app-button
                        name="パスワードリセット"
                        @click="resetApply"
                        ref="jest-password-reset-submit"
                    />
                    <app-button
                        name="キャンセル"
                        @click="back"
                        class="cancel"
                        ref="jest-password-reset-back"
                    />
                </div>
            </div>
            <div
                v-else
                key="resetMessage"
                ref="jest-password-reset-message"
                class="uq_passwordEmail_issend"
            >
                <p class="bl_msgBox">パスワードリセット用のメールを送信しました。<br />受信したメールに従ってパスワードの再登録をお願いします。</p>
                <div class="ly_buttonBox">
                    <app-button
                        name="戻る"
                        @click="back"
                        class="cancel"
                        ref="jest-password-reset-close"
                    />
                </div>
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties, move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: "AuthenticationPasswordEmail",
    data(){
        return {
            isSend:false,
        }
    },
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse',
            data:'auth/email'
        }),
        ...mapComputedProperties([
            'email',
        ]),
        type(){
            return this.isHouse ? 'house' : 'building'
        }
    },
    methods:{
        ...mapActions({
            update:'auth/update',
            sendResetLinkEmail:'auth/sendResetLinkEmail',
            updateError:'error/update',
            clearError:'error/clear'
        }),
        back(){
            const path = '/login/' + this.type
            move(this.$router, path)
        },
        resetApply(){
            this.clearError()
            return this.sendResetLinkEmail(this.type)
                .then(response => {
                    if (response.status == 200) {
                        this.isSend = true
                    } else {
                        this.updateError(response)
                    }
                })
        },
    }
}
</script>

<style scoped>
.uq_passwordEmail{
    min-width: 500px;
}
.uq_passwordEmail .ly_form_box{
    margin: 22px 0;
    padding: 32px;
}
.uq_passwordEmail .ly_form_box >>>  .formrow{
    grid-template-columns: 9em 1fr;
}
.uq_passwordEmail .ly_form_box >>>  .formrow input[type="text"]{
    min-width: 20em;
}
.bl_msgBox{
    margin: 22px 0 0;
}
</style>