<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper">
            <div v-if="isHouse">
                <section>
                    <h3 class="el_lv3Heading">一般社団法人 住宅性能評価・表示協会 ホームページ
                        <span class="hp_nowrap">温熱・省エネ設備機器等ポータルサイト（住宅版）</span>
                        <span class="hp_nowrap">リンク掲載募集のご案内</span>
                    </h3>
                    <div class="bl_guide">
                        <p><span class="hp_zenkakuspace"></span>本検索サイトは建築物省エネ法に基づく「住宅に関する省エネルギー基準に準拠したプログラム」等に必要な性能値等が掲載された企業のホームページの一元化を図ることで、設計者の負担を軽減することを目的として構築しております。<br />
                        <span class="hp_zenkakuspace"></span>本検索サイトを活用し御社製品の認知度向上に是非役立てて頂きたく、この機会にリンク掲載のお申込みをご検討いただければ、幸甚でございます。 <br />
                        <span class="hp_zenkakuspace"></span>お申込みにあたりましては、別添の温熱・設備機器等ポータルサイト（住宅版）リンク掲載概要等をお読みいただき、所定の手続きで、お申し込み下さいますようお願い申し上げます。</p>
                    </div>
                </section>
                <section>
                    <h3 class="el_lv3Heading">温熱・省エネ設備機器等ポータルサイト（住宅版）<span class="hp_nowrap">リンク掲載概要</span></h3>
                    <ol>
                        <li>
                            リンクを掲載するサイト<br>
                            https://shoenekikiportal.hyoukakyoukai.or.jp/#/house（評価協会トップ画面より入れます）
                        </li>
                        <li>
                            リンク掲載お申込み期間<br>
                            随時
                        </li>
                        <li>
                            リンク掲載場所<br>
                            温熱・省エネ設備機器等ポータルサイト（住宅版）内における各建材等の検索結果（※下図参照）<br>
                            
                            ※検索結果に表示される事業者様名の順序はアクセスの度にランダムに入れ変わります
                            <img src="/img/link_organization_image.png" class="uq_link_image"/>
                            
                        </li>
                        <li>
                            リンク掲載のためのアカウント取得料金およびアカウント更新料金（税抜）
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>一般の企業・団体</th>
                                        <th>一般社団法人リビングアメニティ協会<br />一般社団法人日本建材・住宅設備産業協会</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>（アカウント３つの使用料含む）</td>
                                        <td class="hp_alignCenter">60,000円</td>
                                        <td class="hp_alignCenter">30,000円</td>
                                    </tr>
                                    <tr>
                                        <td>4つ以上のアカウントを希望する場合、以降アカウント１つごと</td>
                                        <td class="hp_alignCenter">10,000円</td>
                                        <td class="hp_alignCenter">5,000円</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>※上記は年度ごとに必要な料金です。<span class="hp_str_bold">（当年度の料金は、6月頃決定致します。）</span><br />
                                <span class="hp_str_bold">※住宅版と非住宅版に登録する場合はそれぞれ別個に費用が必要になります。</span><br />
                                <span class="hp_str_underline">※本アカウントとして付与するＩＤとパスワードを用いて各掲載事業者様専用ページにログインをして頂き、ご自身でリンクの掲載・更新をして頂けます。</span><br />
                                1アカウントで掲載するリンク数に制限はございませんが、事務局から問合せをする際の窓口担当者は1アカウントにつき1名として頂きます。
                            </p>
                        </li>
                        <li>
                            アカウントの有効期限<br>
                            アカウントは、アカウント発行の日の属する会計年度（4月1日から翌年3月31日まで）の末日までとします。翌年度も引き続きリンク掲載を希望する場合は、更新手続きを行って下さい。
                        </li>
                        <li>
                            リンクの表示<br>
                            検索結果に表示されるリンクは企業名とします。
                        </li>
                        <li>
                            申込方法<br>
                            リンク掲載を希望する場合は、下記の「温熱・省エネ設備機器等ポータルサイトリンク掲載要領」をご確認のうえ、「同意する」ボタンをクリックして登録画面にお進みください。
                        </li>
                        <li>
                            リンク先ページの条件について<br>
                            リンク先ページについては各掲載事業者様にてあらかじめご用意頂きますが、<span class="hp_str_underline">本検索サイトにリンクを掲載する場合は別添の「温熱・省エネ設備機器等ポータルサイトリンク掲載要領（住宅版）」に同意の上、リンク先ページが下記に示す条件に適合している必要がございます。不備若しくは不適切な点がある場合は事務局よりご連絡の上、修正して頂く場合がございますので、あらかじめご了承ください。</span>
                            <div class="uq_borderBox">
                                <h5 class="el_lv5Heading">【リンク先ページの条件】</h5>
                                <ol class="rounded_num">
                                    <li>対象となる製品の型番等で、機器の特定が行える情報を記載すること。</li>
                                    <li>①に記載した製品の商品名等が存する場合は当該名称を記載すること。</li>
                                    <li>低炭素住宅の認定において、計算若しくは入力に必要となる性能値等を記載すること。</li>
                                    <li>③に記載した性能値等について、当該性能を有することを示す根拠を下表に示す区分で表示すること。<br>
                                        <table>
                                            <caption>表 性能確認方法の区分</caption>
                                            <tr>
                                                <th
                                                    colspan="2"
                                                    rowspan="2"
                                                />
                                                <th colspan="3">生産品質</th>
                                            </tr>
                                            <tr>
                                                <th>ISO9001登録工場又は<br />JIS認証取得工場</th>
                                                <th>第三者生産品質審査機関で審査実施</th>
                                                <th>自己適合宣言<br />(JIS Q17050-1)</th>
                                            </tr>
                                            <tr>
                                                <th rowspan="3" class="uq_vertical">試験品質</th>
                                                <th>第三者試験機関で試験実施</th>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">B-1</td>
                                            </tr>
                                            <tr>
                                                <th>第三者試験等審査機関で審査実施</th>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">B-1</td>
                                            </tr>
                                            <tr>
                                                <th>自己適合宣言<br />(JIS Q1000等)</th>
                                                <td class="hp_alignCenter">B-2</td>
                                                <td class="hp_alignCenter">B-2</td>
                                                <td class="hp_alignCenter">C</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li>④で記載した区分について、当該区分であること証する書類の提出を求められた場合、当該資料を提供する用意があること。
                                        <ul>
                                            <li>A区分であれば、第三者機関による試験成績証明書等（又は当該性能等を有していることを示すJIS等のマーク）</li>
                                            <li>B区分であれば、第三者機関による試験成績証明書等と併せて自己適合宣言書</li>
                                            <li>C区分であれば、自己適合宣言書</li>
                                        </ul>
                                    </li>
                                    <li>①～⑤の各事項については、事業者等の責任において実施することとし、その記載方法等は事業者等の判断によることとする。</li>
                                    <li>①～⑥の各事項について、記載内容に関する問い合わせ先を明示すること。</li>
                                    <li>①～⑦の各事項について、記載内容に変更があった場合は、必ずリンクの更新又は削除を行い、運営・管理者にその旨を報告すること。</li>
                                </ol>
                            </div>
                        </li>
                    </ol>
                    <div class="bl_contact">
                        <h5 class="el_lv5Heading">【お問い合わせ先】一般社団法人 住宅性能評価・表示協会 リンク掲載事務局</h5>
                        <div class="bl_contact_flex">
                            <p><span>ＴＥＬ</span>03－5229－7440</p>
                            <p><span>ＦＡＸ</span>03－5229－7443</p>
                        </div>
                        <p><span>住 所</span>〒162-0825 東京都新宿区神楽坂1-15 神楽坂1丁目ビル6階</p>
                        <p><span>メールアドレス</span>link@hyoukakyoukai.or.jp</p>
                    </div>
                </section>
                <section>
                    <h3 class="el_lv3Heading">【温熱・省エネ設備機器等ポータルサイト（住宅版）リンク掲載要領】</h3>
                    <p>一般社団法人 住宅性能評価・表示協会（以下「甲」という。）及び温熱・省エネ設備機器等ポータルサイト（住宅版）リンク掲載申込者（以下「乙」という。）は、リンク掲載（以下「本サービス」）の実施にあたり、下記事項を遵守致します。</p>
                    <div>
                        <h5 class="el_lv5Heading">第１条<span class="hp_zenkakuspace"></span>本サービス内容</h5>
                        <ol>
                            <li>本契約に基づく本サービスの適用範囲は、甲が管理するサイト内に掲載する乙の「企業名」によるリンクの作成、更新、削除機能とする。</li>
                            <li>本サービスを提供する場所、アカウントの有効期限等は、第４条リンク掲載場所、第７条アカウントの有効期限に定めるとおりとする。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第２条<span class="hp_zenkakuspace"></span> 本サービスの申込みと年度更新</h5>
                        <ol>
                            <li>乙は、本サービス申込みについて所定の申込書に記載し、電子メールにて甲に送付するものとする。</li>
                            <li>乙は、本サービス申込みによって掲載されるリンクのリンク先ページの仕様については第5条第1項を満たさねばならない。</li>
                            <li>甲は、乙が掲載しようする情報が本サービスで提供する情報として不適当と判断した場合、本サービスの申込みを拒否することができる。</li>
                            <li>第３項に係る不適当の判断は、第８条第２項に示すものの他、甲の判断基準によるものとし、甲はその理由を開示する義務を負わない。</li>
                            <li>乙は、本サービスの申込みを行った当該年度の翌年度も引き続き本サービスを利用する場合においては、甲が案内する年度更新の手続きに従う。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第３条<span class="hp_zenkakuspace"></span> 支払方法</h5>
                        <ol>
                            <li>甲は、乙からの本サービス申込みの電子メールが到着後、遅滞なく乙に対し、申込書に記載されるアカウント料金に消費税相当額を加算した額（以下「契約金額」という。）にかかる請求書を発行するものとする。</li>
                            <li>契約金額は年額とし、第７条に定める有効期限の残存日数に関わらず一定とする。</li>
                            <li>乙は、甲から請求書受領後速やかに（以下「支払期間」という。）に別途甲が指定する銀行口座に契約金額を振り込むことにより支払うものとする。なお、振込み手数料は、乙の負担とする。</li>
                            <li>乙が前項に定める期限までに契約金額を支払わない場合は、甲は、支払い期日の翌日から実際に支払いが行われた日まで、年利６%で計算された金額を支払い遅延利息として乙に請求することができるものとする。</li>
                            <li>当該遅延利息の額に１円未満の端数があるときはその端数を切り捨てるものとする</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第４条<span class="hp_zenkakuspace"></span> リンク掲載場所</h5>
                        <ol>
                            <li>本サービスは、甲の管理する温熱・設備機器等ポータルサイト（住宅版）に掲載する。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第５条<span class="hp_zenkakuspace"></span> 本サービス利用の要件</h5>
                        <ol>
                            <li>乙は、掲載するリンク先ページが下記を満たしていることとする。
                                <ol style="">
                                    <li>対象となる製品の型番等で、機器の特定が行える情報を記載すること。</li>
                                    <li>1.に記載した製品の商品名等が存する場合は当該名称を記載すること。</li>
                                    <li>外皮計算及び一次エネルギー消費量計算等において必要となる性能値等を記載すること。</li>
                                    <li>3.に記載した性能値等について、当該性能を有することを示す根拠を下表に示す区分で表示すること。
                                        <table>
                                            <caption>表<span class="hp_zenkakuspace"></span> 性能確認方法の区分</caption>
                                            <tr>
                                                <th
                                                    colspan="2"
                                                    rowspan="2"
                                                />
                                                <th colspan="3">生産品質</th>
                                            </tr>
                                            <tr>
                                                <th>ISO9001登録工場又はJIS認証取得工場</th>
                                                <th>第三者生産品質審査機関で審査実施</th>
                                                <th>自己適合宣言(JIS Q17050-1)</th>
                                            </tr>
                                            <tr>
                                                <th rowspan="3" class="uq_vertical">試験品質</th>
                                                <th>第三者試験機関で試験実施</th>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">B-1</td>
                                            </tr>
                                            <tr>
                                                <th>第三者試験等審査機関で審査実施</th>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">A</td>
                                                <td class="hp_alignCenter">B-1</td>
                                            </tr>
                                            <tr>
                                                <th>自己適合宣言(JIS Q1000等)</th>
                                                <td class="hp_alignCenter">B-2</td>
                                                <td class="hp_alignCenter">B-2</td>
                                                <td class="hp_alignCenter">C</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li>(4)で記載した区分について、当該区分であること証する書類の提出を求められた場合、当該資料を提供する用意があること。
                                        <ul>
                                            <li>A区分であれば、第三者機関による試験成績証明書等（又は当該性能等を有していることを示すJIS等のマーク）</li>
                                            <li>B区分であれば、第三者機関による試験成績証明書等と併せて自己適合宣言書</li>
                                            <li>C区分であれば、自己適合宣言書</li>
                                        </ul>
                                    </li>
                                    <li>(1)～(5)の各事項については、事業者等の責任において実施することとし、その記載方法等は事業者等の判断によることとする。</li>
                                    <li>(1)～(6)の各事項について、記載内容に関する問い合わせ先を明示すること。</li>
                                </ol>
                            </li>
                            <li>乙は、前項の内容に変更があった場合は、遅滞なく更新又は削除の手続きを行うこと。</li>
                            <li>甲は、乙が掲載するリンク先ページが第１項を満たしていることを確認する。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第６条<span class="hp_zenkakuspace"></span> アカウントの発行</h5>
                        <ol>
                            <li>甲は乙の契約金額の振込を確認次第、直ちに本サービスに必要なアカウントを乙に送付しなければならない。</li>
                            <li>甲は乙に対して、前項の申込みに応じて3つのアカウント（ＩＤとパスワードの対）を付与する。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第７条<span class="hp_zenkakuspace"></span> アカウントの有効期限</h5>
                        <ol>
                            <li>アカウントの有効期限は、アカウント発行の日の属する会計年度（4月1日から翌年3月31日まで）の末日までとする。</li>
                            <li>前項の有効期限内にアカウントの更新申請を乙が行わない場合は、甲は当該会計年度の末日をもって乙への本サービスの提供を中止する。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第８条<span class="hp_zenkakuspace"></span> 乙の責任</h5>
                        <ol>
                            <li>本サービスに関して第三者から訂正依頼、クレーム、異議又は損害賠償の請求が提起された場合、乙は、当該クレーム、異議又は損害賠償の請求を自己の費用と責任において解決しなければならず、また、甲に対して一切迷惑をかけてはならないものとする。</li>
                            <li>本サービスの実施にあたり、掲載する企業名及びリンク先ページの掲載情報が次の各号に該当しないこととする。
                                <ol>
                                    <li>法律・政令及び省令・規則・行政指導に違反すること。</li>
                                    <li>第三者の著作権、商標権等の知的財産権を侵害すること。</li>
                                    <li>第三者の名誉・信用・プライバシー・肖像権等の人格的権利を侵害すること。</li>
                                    <li>特定の団体・個人を誹謗中傷すること</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第９条<span class="hp_zenkakuspace"></span> 中断</h5>
                        <ol>
                            <li>甲は、本サービスを提供する甲のサイト又は本サービスを管理するためのシステムの緊急保守、更改、障害、火災、停電、天災等の事由により甲が必要と認めた場合、甲は、乙に事前に通知することなく一時的に本サービスの全部又は一部を中断することができるものとする。なお、この場合、甲は、可及的速やかに再開するよう努めるものとする。</li>
                            <li>本サービスの中断に関する甲の乙に対する責任は、一切負わないものとする。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第１０条<span class="hp_zenkakuspace"></span> 本サービスの取りやめについて</h5>
                        <ol>
                            <li>甲は乙が次の各号のいずれかに該当するときは、掲載を取りやめることができるものとする。
                                <ol>
                                    <li>掲載要領にそぐわない内容でリンク及びリンク先ページが作成され、甲の修正依頼に乙が応じなかった場合。</li>
                                    <li>差押え、仮差押え、強制執行、仮処分若しくは競売の申立てがあったとき又は租税滞納処分を受けたとき。</li>
                                    <li>破産、会社整理開始の申立てがあった等の復権を得ないことが判明したとき。</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </section>
            </div>
            <div v-else>
                <section>
                    <h3 class="el_lv3Heading">
                        温熱・省エネ設備機器ポータルサイト（非住宅版）
                        <span class="hp_nowrap">リンク掲載募集のご案内</span>
                    </h3>
                    
                    <div class="bl_guide">
                        <p>
                            平成29年2月20日<br>
                            一般社団法人 住宅性能評価・表示協会
                        </p>
                        <p>平成29年4月1日より、建築物省エネ法の規制措置が施行され、2000㎡以上の非住宅建築物について、新築時等に省エネルギー基準への適合が義務化されることとなっております。これにともない、省エネルギー基準への適合義務対象となる建築物(義務対象建築物)に使用する建材・設備機器について、工事監理等の際に、原則として、所定の条件で測定・試験された性能値等であることを、第三者認証書、試験成績証明証または自己適合宣言書等により確認がなされることとされております。</p>
                        <p>これを受け、一般社団法人 住宅性能評価・表示協会では、温熱・省エネ設備機器等ポータルサイトの非住宅版の運営を開始し、各設備等建材事業者様よりリンク掲載のお申し込みの受付を行っております。</p>
                        <p>本ポータルサイトは、建築物省エネ法の規制措置に係る必要な情報が掲載された企業のホームページの一元化を図ることで、制度の円滑な運用を目的として構築されております。具体的には、適合義務対象となる建築物に係る手続きにおいて以下のような活用が可能となります。なお、当面の間の運用として、本ポータルサイトにおいては、モデル建物法に対応可能な情報を掲載することとしております。</p>
                        <ol class="rounded_num">
                            <li>工事監理等の際、納入した製品の性能証明書類（第三者認証・試験成績証明書・自己適合宣言書等）の確認に用いることができます。</li>
                            <li>省エネ計算の際、入力する性能値等の確認に用いることができます。</li>
                            <li>製品納入等の際、納入仕様書等により、性能値等を確認することができない場合、納入した製品が記載されているページを添付することにより、性能値等を明示することができます。</li>
                        </ol>
                        <p>多くの設備機器等を備える非住宅建築物において、各設備機器等のJIS規格に基づく性能値の把握や、試験品質等の証明資料の確認は、設計側、施工側、審査側にとって大きな負担となります。</p>
                        <p>本検索サイトを活用することで、この負担を軽減し更には御社製品の認知度向上に是非役立てて頂きたく、この機会にリンク掲載のお申込みをご検討いただければ、幸甚でございます。</p>
                        <p>お申込みにあたりましては、以下の「温熱・省エネ設備機器等ポータルサイト（非住宅版）リンク掲載概要」をご確認いただき、所定の手続きで、お申し込みくださいますようお願い申し上げます。</p>
                    </div>
                </section>
                <section>
                    <h3 class="el_lv3Heading">温熱・省エネ設備機器ポータルサイト（非住宅版）<span class="hp_nowrap">リンク掲載概要</span></h3>
                    <ol>
                        <li>
                            リンクを掲載するサイト<br>
                            https://shoenekikiportal.hyoukakyoukai.or.jp/#/building/<br>
                            （評価協会ホームページトップ画面より入れます）
                        </li>
                        <li>
                            リンク掲載お申込み期間<br>
                            随時受け付けます。
                        </li>
                        <li>
                            リンク掲載場所<br>
                            温熱・省エネ設備機器ポータルサイト（非住宅版）における各建材等の検索結果（※下図参照）<br>
                            ※検索結果に表示される事業者様名の順序はアクセスの度にランダムに入れ変わります
                        </li>
                        <li>
                            リンク掲載のためのアカウント取得料金およびアカウント更新料金（税抜）<br>
                            ポータルサイトの社会的意義等を鑑み、アカウント取得料金及びアカウント更新料金については、無料とします。
                        </li>
                        <li>
                            リンクの表示<br>
                            検索結果に表示されるリンクは原則企業名とします。
                        </li>
                        <li>
                            申込方法<br>
                            リンク掲載を希望する場合は、温熱・省エネ設備機器等ポータルサイト（非住宅版）リンク掲載募集のご案内ページにアクセスいただき、必要事項のご入力、ご確認を行なった上で送信してください。なお、上記必要事項に変更が生じた場合は、速やかにその旨を運営・管理者に報告を行ってください。<br>
                            温熱・省エネ設備機器等ポータルサイト（非住宅版）リンク掲載募集のご案内ページURL<br>
                            https://shoenekikiportal.hyoukakyoukai.or.jp/#/organization/guide
                        </li>
                        <li>
                            リンク先ページの条件について<br>
                            リンク先ページについては下記リンク先ページの条件に基づき各掲載事業者様にてご用意頂きます。また、リンク先ページを新たに作成し掲載する場合は、下記リンク先ページの条件を満たしていることについて、申請を行い、承認を得る必要がございます。不備等がある場合は運営・管理者よりご連絡の上、修正して頂く場合がございますので、あらかじめご了承ください。<br>
                            <div class="uq_borderBox">
                                <h5 class="el_lv5Heading">【リンク先ページの条件】</h5>
                                <ol class="rounded_num">
                                    <li>
                                        以下のア）～カ）の項目を掲載すること。
                                        <ol class="japanese_character">
                                            <li>対象となる製品の型番等で、機器の特定が行える情報を記載すること。</li>
                                            <li>建築物省エネ法の適合性判定においてモデル建物法を用いた場合の、計算若しくは入力に必要となる性能値等を記載すること。各設備の入力に必要となる性能値等は別紙を確認のこと。<br>
                                                ※別紙は
                                                <a  class="hp_str_underline" 
                                                    href="\pdf\site_search_guidelines_besshi.pdf"
                                                    target="_blank"
                                                >
                                                    入力に必要となる設備機器等の性能値及びリンク先のイメージ一覧

                                                </a>
                                                をご確認ください。
                                            </li>
                                            <li>イ）に記載した性能値等について、性能証明書類（第三者認証書、試験成績証明書、自己適合宣言書等）を掲載すること。</li>
                                            <li>型番による検索機能等、納入した製品との照合を可能とすること。</li>
                                            <li>本リンク掲載概要に基づくものである旨を明記すること。</li>
                                            <li>記載内容に関する問い合わせ先を明示すること。</li>
                                        </ol>
                                    </li>
                                    <li>性能証明書類に記載された設備機器等の性能値等の定義に見直しがあった場合、また新たに定められた性能値等の定義に基づく性能証明書類を掲載する場合は、運営・管理者にその旨を報告すること。</li>
                                </ol>
                                【参考】リンク先ページにおいては、試験品質についての性能証明書類の添付を条件としているが、生産品質を含めた性能確認方法として下表による区分もある。
                                <table>
                                    <caption>表<span class="hp_zenkakuspace"></span> 性能確認方法の区分</caption>
                                    <tr>
                                        <th
                                            colspan="2"
                                            rowspan="2"
                                        />
                                        <th colspan="3">生産品質</th>
                                    </tr>
                                    <tr>
                                        <th>ISO9001登録工場又はJIS認証取得工場</th>
                                        <th>第三者生産品質審査機関で審査実施</th>
                                        <th>自己適合宣言(JIS Q17050-1)</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="3" class="uq_vertical">試験品質</th>
                                        <th>第三者試験機関で試験実施</th>
                                        <td class="hp_alignCenter">A</td>
                                        <td class="hp_alignCenter">A</td>
                                        <td class="hp_alignCenter">B-1</td>
                                    </tr>
                                    <tr>
                                        <th>第三者試験等審査機関で審査実施</th>
                                        <td class="hp_alignCenter">A</td>
                                        <td class="hp_alignCenter">A</td>
                                        <td class="hp_alignCenter">B-1</td>
                                    </tr>
                                    <tr>
                                        <th>自己適合宣言(JIS Q1000等)</th>
                                        <td class="hp_alignCenter">B-2</td>
                                        <td class="hp_alignCenter">B-2</td>
                                        <td class="hp_alignCenter">C</td>
                                    </tr>
                                </table>
                                上記区分について、当該区分であることを証する書類は以下の通りとなる。
                                <ul>
                                    <li>A区分であれば、第三者機関による試験成績証明書等（又は当該性能等を有していることを示すJIS等のマーク）</li>
                                    <li>B-1区分であれば、第三者機関による試験成績証明書等と併せて自己適合宣言書</li>
                                    <li>B-2区分であれば、ISO9001登録証又はJIS認証書と併せて自己適合宣言書</li>
                                    <li>C区分であれば、自己適合宣言書</li>
                                </ul>
                            </div>
                        </li>
                    </ol>
                    <div class="bl_contact">
                        <h5 class="el_lv5Heading">【お問い合わせ先】一般社団法人 住宅性能評価・表示協会 リンク掲載事務局</h5>
                        <div class="bl_contact_flex">
                            <p><span>ＴＥＬ</span>03－5229－7440</p>
                            <p><span>ＦＡＸ</span>03－5229－7443</p>
                        </div>
                        <p><span>住 所</span>〒162-0825 東京都新宿区神楽坂1-15 神楽坂1丁目ビル6階</p>
                        <p><span>メールアドレス</span>link1@hyoukakyoukai.or.jp</p>
                    </div>
                </section>
                <section>
                    <h3 class="el_lv3Heading">【温熱・省エネ設備機器ポータルサイト（非住宅版）リンク掲載要領】</h3>
                    <p>平成29年2月20日</p>
                    <p>一般社団法人 住宅性能評価・表示協会（以下「甲」という。）及び温熱・省エネ設備機器ポータルサイト（非住宅）リンク掲載申込者（以下「乙」という。）は、リンク掲載（以下「本サービス」）の実施にあたり、下記事項を遵守致します。</p>
                    <div>
                        <h5 class="el_lv5Heading">第１条<span class="hp_zenkakuspace"></span> 本サービス内容</h5>
                        <ol>
                            <li>本要領に基づく本サービスの適用範囲は、甲が管理するサイト内に掲載する乙の「企業名」によるリンクの作成、更新、削除機能とする。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第２条<span class="hp_zenkakuspace"></span> 本サービスの申込み</h5>
                        <ol>
                            <li>乙は、本サービス申込みについて、温熱・省エネ設備機器ポータルサイト（非住宅版）リンク掲載募集のご案内ページ（https://shoenekikiportal.hyoukakyoukai.or.jp/#/organization/guide）にアクセスし本要領に同意の上、必要事項を記載し、甲に送信するものとする。なお、必要事項は①～⑤の通り。
                                <ol class="rounded_num">
                                    <li>会社名</li>
                                    <li>会社所在地(本社)</li>
                                    <li>担当部署</li>
                                    <li>担当者氏名及び連絡先</li>
                                    <li>その他必要な事項</li>
                                </ol>
                            </li>
                            <li>甲は、乙が掲載しようする情報が本サービスで提供する情報として不適当と判断した場合、本サービスの申込みを拒否することができる。</li>
                            <li>前項に係る不適当の判断は、第６条第２項に示すものの他、甲の判断基準によるものとし、甲はその理由を開示する義務を負わない。</li>
                            <li>甲は、第２項に係る判断に要する資料等を乙に求めることができる。</li>
                            <li>乙は、第１項の必要事項に変更が生じた場合は、速やかにその旨を運営・管理者に報告しなければならない。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第３条<span class="hp_zenkakuspace"></span> 本サービスの提供場所</h5>
                        <ol>
                            <li>本サービスは、甲の管理する温熱・省エネ設備機器ポータルサイト（非住宅版）に掲載する。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第４条<span class="hp_zenkakuspace"></span> 本サービス利用の要件</h5>
                        <ol>
                            <li>乙は、掲載するリンク先ページについて下記を満たすこととする。<br>
                                以下のア）～カ）の項目を掲載すること。
                                <ol class="japanese_character">
                                    <li>対象となる製品の型番等で、機器の特定が行える情報を記載すること。</li>
                                    <li>建築物省エネ法の適合性判定においてモデル建物法を用いた場合の、計算若しくは入力に必要となる性能値等を記載すること。各設備の入力に必要となる性能値等は別紙を確認のこと。<br>
                                        ※別紙は
                                        <a
                                            href="\pdf\site_search_guidelines_besshi.pdf"
                                            target="_blank"
                                        >
                                            入力に必要となる設備機器等の性能値及びリンク先のイメージ一覧

                                        </a>
                                        をご確認ください。
                                    </li>
                                    <li>イ）に記載した性能値等について、性能証明書類（第三者認証書、試験成績証明書、自己適合宣言書等）を掲載すること。</li>
                                    <li>型番による検索機能等、納入した製品との照合を可能とすること。</li>
                                    <li>本要領に基づくものである旨を明記すること。</li>
                                    <li>記載内容に関する問い合わせ先を明示すること。</li>
                                </ol>
                            </li>
                            <li>乙は、前項ウ）の性能証明書類に記載された設備機器等の性能値等の定義に見直しがあった場合、また新たに定められた性能値等の定義に基づく性能証明書類を掲載する場合は、運営・管理者にその旨の報告を行うこと。</li>
                            <li>乙は、リンク先ページを新たに作成し公開する場合は、第１項を満たしていることについて、甲に申請を行い、承認を得なければならない。なお、上記申請から承認に要する期間は概ね２週間とする。</li>
                            <li>は、乙が掲載するリンク先ページが第１項を満たしていることについて適宜確認を行い、不備等がある場合は、乙に修正を求めることができる。</li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第５条<span class="hp_zenkakuspace"></span> 本サービスの取りやめについて</h5>
                        <ol>
                            <li>乙は、本サービスを取りやめる場合、サービス停止の１週間前までに電子メールにて運営・管理者に申し出なければならない。</li>
                            <li>甲は乙が次の各号のいずれかに該当し、甲の修正依頼に乙が応じなかった場合は、２週間の経過措置を以て掲載を取りやめることができるものとする。
                                <ol>
                                    <li>本要領にそぐわない内容にリンク先ページが更新されている場合。</li>
                                    <li>第４条第４項による確認において、リンク先ページの確認が行えなかった場合。</li>
                                    <li>リンク先ページの内容に疑義等が生じた場合。</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第６条<span class="hp_zenkakuspace"></span> 乙の責任</h5>
                        <ol>
                            <li>本サービスに関して第三者から訂正依頼、クレーム、異議又は損害賠償の請求が提起された場合、乙は、当該クレーム、異議又は損害賠償の請求を自己の費用と責任において解決しなければならず、また、甲に対して一切迷惑をかけてはならないものとする。</li>
                            <li>本サービスの実施にあたり、掲載する企業名及びリンク先ページの掲載情報が次の各号に該当しないこととする。
                                <ol>
                                    <li>法律・政令及び省令・規則・行政指導に違反すること。</li>
                                    <li>第三者の著作権、商標権等の知的財産権を侵害すること。</li>
                                    <li>第三者の名誉・信用・プライバシー・肖像権等の人格的権利を侵害すること。</li>
                                    <li>特定の団体・個人を誹謗中傷すること。</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h5 class="el_lv5Heading">第７条<span class="hp_zenkakuspace"></span> 中断</h5>
                        <ol>
                            <li>甲は、本サービスを提供する甲のサイト又は本サービスを管理するためのシステムの緊急保守、更改、障害、火災、停電、天災等の事由により甲が必要と認めた場合、甲は、乙に事前に通知することなく一時的に本サービスの全部又は一部を中断することができるものとする。なお、この場合、甲は、可及的速やかに再開するよう努めるものとする。</li>
                            <li>本サービスの中断に関する甲の乙に対する責任は、一切負わないものとする。</li>
                        </ol>
                    </div>
                </section>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="reject"
                    ref="jest-guide-reject"
                    class="cancel"
                />
                <app-button
                    name="同意します"
                    @click="accept"
                    ref="jest-guide-agree"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P001 新規事業者案内ページ
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「同意します」ボタン押下時 ... 同意されたことをStoreに記録し、記載内容を初期化して登録画面へ遷移
 * 「戻る」ボタン押下時 ... 評価協会のTopに遷移
 */
import { mapActions, mapGetters } from 'vuex'
import { move, defaultValue } from '@/util'

export default {
    name:"OrganizationGuide",
    computed:{
        ...mapGetters({
            isHouse:'status/isHouse'
        }),
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        }
    },
    methods:{
        ...mapActions({
            updateStatus:'status/update',
            updateUser:'register/user/update',
            updateOrganization:'register/organization/update',
            clearError:'error/clear'
        }),
        accept(){
            this.clearError()
            this.updateStatus({accepted:true})
            this.updateOrganization(defaultValue.organization)
            this.updateUser(defaultValue.user)
            move(this.$router, '/organization/register')
        },
        reject(){
            this.clearError()
            move(this.$router, '/top')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    max-width: 1000px;
}
/* テーブル内装飾 */
.uq_vertical{
    max-width: calc(1em + 32px);
}
.uq_borderBox{
    border: 1px solid #F1F4EC;
    margin-top: 1em;
    padding: 16px;
}
.bl_contact{
    width: fit-content;
    margin: 32px auto 16px;
    padding: 16px 32px;
    background-color: #F1F4EC;
}
.bl_contact .el_lv5Heading{
    padding-bottom: 4px;
    border-bottom: 1px solid #4A6533;
}
.bl_contact_flex{
    display: flex;
    margin: 0;
}
.bl_contact_flex>p{
    width: 15em;
}
.bl_contact p{
    margin: 0;
}
.bl_contact p span{
    display: inline-block;
    min-width: 3em;
    margin: 0 16px 0 0;
}
.rounded_num {
    counter-reset: chap;
    list-style:none;
}
.rounded_num > li{
    position: relative;
}
.rounded_num > li::before {
    counter-increment: chap;
    content:counter(chap);
    display: inline-flex;
    justify-content: center;
    align-items:center;
    width: 1.2em;
    height: 1.2em;
    font-size: 0.825em;
    border-radius: 0.6em;
    border:0.25px solid rgba(0,0,0, .7);
    background-color: white;
    position: absolute;
    left: -1.75em;
    top: 0.2em;
}
.japanese_character {
    list-style-type:katakana;
}
.uq_link_image{
    width: 70%;
    min-width: 500px;
}
</style>