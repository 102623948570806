<template>
    <div class="form_box ly_form_box ly_userForm">
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password"
                label="現在のパスワード"
                class="lbl_required"
            />
            <input
                id="old_password"
                type="password"
                name="password"
                v-model="password"
                class="rowcontents"
            />
        </div>
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password"
                label="新しいパスワード"
                class="lbl_required"
            />
            <input
                type="password"
                name="password"
                v-model="newPassword"
                class="rowcontents"
            />
        </div>
        <div
            class="formrow"
            v-if="isNew"
        >
            <app-label
                name="password_confirmation"
                label="新しいパスワード（確認）"
                class="lbl_required"
            />
            <input
                type="password"
                name="password_confirmation"
                v-model="newPasswordConfirmation"
                class="rowcontents"
            />
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
    name:'UserForm',
    props:{
        isNew:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapGetters({
            data:'register/user/getAll',
        }),
        ...mapComputedProperties([
            'password',
            'newPassword',
            'newPasswordConfirmation',
        ])
    },
    methods:{
        ...mapActions({
            update:'register/user/update'
        })
    }
}
</script>

<style scoped>
</style>