<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="false"
    >
        <div class="bl_contents_wrapper uq_passwordEmail">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">パスワードリセット</h2>
            </div>
            <div
                v-if="!isSend"
                key="resetForm"
                ref="jest-password-reset-form"
            >
                <div class="ly_form_box">
                    <p>以下の項目を入力し、パスワードリセットボタンをクリックしてください。</p>
                    <div>
                        <error-list />
                        <app-text
                            v-model="email"
                            name="email"
                            label="メールアドレス"
                            class="formrow"
                            ref="jest-password-email"
                        />
                        <app-text
                            v-model="password"
                            name="password"
                            label="パスワード"
                            class="formrow"
                            type="password"
                            ref="jest-password-password"
                        />
                        <app-text
                            v-model="password_confirm"
                            name="password_confirm"
                            label="パスワード確認"
                            class="formrow"
                            type="password"
                            ref="jest-password-password-confirm"
                        />
                    </div>
                </div>
                <div class="ly_buttonBox">
                    <app-button
                        name="パスワードリセット"
                        @click="resetApply"
                        ref="jest-password-reset-submit"
                    />
                </div>
            </div>
            <div
                v-else
                key="resetMessage"
                ref="jest-password-reset-message"
                class="uq_passwordEmail_issend"
            >
                <p class="bl_msgBox">パスワードリセットが完了しました。</p>
                <div class="ly_buttonBox">
                    <app-button
                        name="ログイン"
                        @click="login"
                        ref="jest-password-reset-login"
                    />
                </div>
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties, move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: "AuthenticationPasswordReset",
    data(){
        return {
            isSend:false,
        }
    },
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'auth/reset'
        }),
        ...mapComputedProperties([
            'email',
            'password',
            'password_confirm',
        ]),
        type(){
            return this.$route.params.type
        },
        token(){
            return this.$route.params.token
        },
    },
    mounted(){
        this.update({
            email:'',
            password:'',
            password_confirm:''
        })
    },
    methods:{
        ...mapActions({
            update:'auth/update',
            resetPassword:'auth/resetPassword',
            updateError:'error/update',
            clearError:'error/clear'
        }),
        login(){
            const type = this.type === 'building' ? this.type : 'house'
            const path = '/login/' + type
            move(this.$router, path)
        },
        resetApply(){
            this.clearError()
            return this.resetPassword({
                type:this.type,
                token:this.token,
            })
                .then(response => {
                    if (response.status == 200) {
                        this.isSend = true
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.uq_passwordEmail .ly_form_box{
    margin: 22px 0;
    padding: 32px;
}
.uq_passwordEmail .ly_form_box >>>  .formrow{
    grid-template-columns: 9em 1fr;
}
.uq_passwordEmail .ly_form_box >>>  .formrow input[type="text"]{
    min-width: 20em;
}
.bl_msgBox{
    width: 100%;
    margin: 22px 0 0;
    text-align: center;
}
</style>