<template>
    <default-template
        title="ページが見つかりません"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="false"
        :is-house="false"
    >
        <p>エラー：ページが見つかりません。</p>
        <app-button
            name="戻る"
            @click="moveToTop"
            class="cancel"
        />
    </default-template>
</template>

<script>
/**
 * 404 Error
 *
 * [イベント処理]
 * 戻るボタンクリック時 ... トップへ戻る
 */
import AppButton from '@/components/atoms/AppButton.vue'
import { move } from '@/util'

export default {
    components:{
        AppButton
    },
    methods:{
        moveToTop(){
            move(this.$router, '/')
        }
    }
}
</script>

<style scoped>

</style>