<template>
    <default-template
        title="パスワード変更"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">パスワード変更</h2>
                <hr class="hp_flexspacer"/>
                <p>パスワードを入力してください。</p>
            </div>
            <error-list />
            <div class="uq_userEditBox">
                <password-form />
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="cancel"
                    ref="jest-password-change-cancel"
                    class="cancel"
                />
                <app-button
                    name="変更"
                    @click="submit"
                    ref="jest-password-change-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P104_パスワード変更
 *
 */
import ErrorList from '@/components/molecules/ErrorList'
import PasswordForm from '@/components/organisms/PasswordForm'
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'

export default {
    name:'PasswordEdit',
    components:{
        ErrorList,
        PasswordForm,
    },
    mounted(){
        scroll(0, 0)
        if(document.getElementById('old_password') != null){
            document.getElementById('old_password').focus()
        }
    },
    computed:{
        ...mapGetters({
            prevalidate:'register/user/prevalidatePassword',
            prevalidateError:'register/user/prevalidatePasswordError',
            user:'register/user/getAll',
            organization:'auth/organization',
        }),
        isHouse(){
            return this.organization.type == 1
        },
        isOrganization(){
            return this.user.organization_id !== null
        },
        isAdministrator(){
            return this.user.is_system_administrator
        }
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            updatePassword:'register/user/updatePassword'
        }),
        moveTo(path){
            move(this.$router, path)
        },
        cancel(){
            this.clearError()
            this.moveTo('/organization/organization')
        },
        submit(){
            this.clearError()
            if(this.prevalidate) {
               this.updatePassword()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/organization/organization')
                    } else {
                        this.updateError(response)
                    }
                })
            } else {
                this.updateError({
                    status:422,
                    data:{
                        errors:this.prevalidateError
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.uq_userEditBox{
    padding: 16px 22px;
    border: 1px solid #CDD6DD;
}
.bl_input_container{
    padding: 4px 0;
}
.uq_userEditBox >>> .form_box {
    padding: 0;
    border:none;
}
div.uq_userEditBox>div.bl_input_container,
div.uq_userEditBox >>> .form_box div.formrow{
    display: grid;
    grid-template-columns: 15em minmax(300px,1fr);
    border-bottom: 1px solid #F1F4EC;
}
div.uq_userEditBox>div.bl_input_container p{
    margin: 0;
}
</style>