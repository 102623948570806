<template>
<div class="login_form_container">
    <app-text
        name="email"
        v-model="email"
        label="メールアドレス"
        ref="email-text-box"
        class="formrow"
    />

    <!-- error-list -->
    <ul
        :class="{ errors: hasEmailErrors }"
        class="validation-errors"
    >
        <li
            ref="email-invalid-error"
            v-if="invalidEmail"
        >
            メールアドレスの形式が不正です。
        </li>
        <li
            ref="email-required-error"
            v-if="requiredEmail"
        >
            メールアドレスが入力されていません。
        </li>
    </ul>
    
    <div class="formrow">
        <app-label label="パスワード"/>
        <app-text-box
            type="password"
            class="rowcontents"
            ref="password-text-box"
            v-model="password"
            @keydown_enter="login"
        />
        
    </div>

    <!-- error-list -->
    <ul
        :class="{ errors: hasPasswordErrors}"
        class="validation-errors"
    >
        <li
            ref="password-invalid-error"
            v-if="invalidPassword"
        >
            パスワードは6文字以上です。
        </li>
        <li
            ref="password-required-error"
            v-if="requiredPassword"
        >
            パスワードが入力されていません。
        </li>
    </ul>

    <!-- ログインボタン -->
    <app-button
        name="ログイン"
        @click="login"
        class="btn_login hover_color__active"
        ref="button-login"
        v-bind:disabled="isLoggingIn"
    />

    <!-- エラーメッセージ -->
    <p
        v-if="message"
        class="login-error"
        ref="login-error"
    >
        {{ message }}
    </p>
</div>
</template>

<script>
/**
 * ログインフォーム
 */

import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties } from '@/util'
import { move } from '@/util'

const required = val => !!val.trim()

export default {
    name: 'LoginForm',
    data() {
        return {
            haveBeenTriedToLogin: false,
            isLoggingIn:false
        }
    },

    computed: {
        ...mapGetters({
            data:'auth/authInfo'
        }),
        ...mapComputedProperties(
            [
                'email',
                'password',
                'message',
            ]
        ),
        /*
            formatではemailの形式に適合するかを判定
        */
        validation () {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return {
                email: {
                    required: required(this.email),
                    format: re.test(this.email)
                },
                password: {
                    required: required(this.password),
                    format: this.password.length > 5
                }
            }
        },
        valid () {
            const validation = this.validation // 先に定義した validation を用いて可否を返す
            const fields = Object.keys(validation)
            let valid = true
            for (let i= 0; i < fields.length; i++) {
                const field = fields[i]
                valid = Object.keys(validation[field])
                    .every(key => validation[field][key])
                if (!valid) { break }
            }
            return valid
        },
        invalidEmail () {
            return !this.validation.email.format && this.haveBeenTriedToLogin
        },
        requiredEmail () {
            return !this.validation.email.required && this.haveBeenTriedToLogin
        },
        hasEmailErrors () {
            return this.invalidEmail || this.requiredEmail
        },
        invalidPassword () {
            return !this.validation.password.format && this.haveBeenTriedToLogin
        },
        requiredPassword () {
            return !this.validation.password.required && this.haveBeenTriedToLogin
        },
        hasPasswordErrors () {
            return this.invalidPassword || this.requiredPassword
        },
        type(){
            return this.$route.path.includes('house') ? 'house':'building'
        }
    },

    methods: {
        ...mapActions({
            update:'auth/update',
            loginAction:'auth/login',
            clearError:'error/clear',
            updateCogenerations:'cogenerations/updateSearchCogenerationsData',
            updateElectricHeatpumps:'electricHeatPumps/updateSearchElectricHeatPumpsData',
            updateHybridWaterHeaters:'hybridWaterHeaters/updateSearchHybridWaterHeatersData',
            updateInformations:'informations/update',
            updateLinks:'links/update',
            updateAdminBrandNames:'admin/brandNames/update',
            updateAdminLinks:'admin/links/update',
            updateAdminOrganization:'admin/organization/update',
            updateAdminSharedPasswords:'admin/sharedPasswords/update',
        }),
        async login () {
            if (this.valid === false) { 
                this.haveBeenTriedToLogin = true  // ログインしようとしたフラグを残す
                return // 不備があればログイン処理が実行されない
            }

            // ログイン中はボタンを非活性にする
            this.isLoggingIn = true

            //ログイン処理の実行
            const user = await this.loginAction(this.type)

            // 管理ユーザであるか否かを判定する
            if (user) {
                this.clearError()
                this.updateCogenerations({page:1})
                this.updateElectricHeatpumps({page:1})
                this.updateHybridWaterHeaters({page:1})
                this.updateInformations({page:1})
                this.updateLinks({page:1})
                this.updateAdminBrandNames({page:1})
                this.updateAdminLinks({page:1})
                this.updateAdminOrganization({page:1})
                this.updateAdminSharedPasswords({page:1})
                if (user.is_system_administrator) {
                    // 管理者である場合
                    move(this.$router, '/admin/organizations')
                } else {
                    // 事業者である場合
                    move(this.$router, '/organization/organization')
                }
            // ログインに失敗した場合
            } else {
                // エラー原因が不明な場合
                if (!this.message) {
                    this.update({errors:'エラーが発生しました。しばらくしてからもう一度お試しください。'})
                }
            }

            // ログインボタンを活性に戻す
            this.isLoggingIn = false
        },
    }
}
</script>

<style scoped>
.login_form_container >>> div{
    width: 100%;
}
.login_form_container >>> label{
    margin-right: 6px;
    font-size: 16px;
    font-weight: bolder;
    color: #1C4373;
}
.login_form_container >>> input[type="text"],
.login_form_container >>> input[type="password"]{
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    margin:0;
    font-size: 16px;
}
.login_form_container >>> .formrow:not(:first-of-type){
    margin-top: 30px;
}
.login_form_container >>> ul.errors,
.login_form_container >>> ul.bl_errorList{
    width: 100%;
    margin-bottom: 20px;
    list-style-type: none;
    padding-inline-start: 0;
}
.login_form_container >>> ul.errors li,
.login_form_container >>> ul.bl_errorList li {
    white-space: normal;
    margin-bottom: 2px;
}
.btn_login{
    display: block;
    margin: 1.5rem auto 0;
    font-size: 16px;
}
.btn_login:disabled:hover{
    opacity: 0.5;
    background-color: #4A6533;
}
.login-error{
    display: block;
    font-size: 14px;
    color:rgb(53, 15, 15);
}
</style>
